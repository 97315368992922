.leaguemgmtbody {
    margin-top: 0;
    padding: 140px 0 100px 0;
    background: linear-gradient(90.21deg, rgba(21, 18, 97, 0.5) -5.91%, rgba(0, 34, 81, 0.5) 111.58%);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .leaguemgmtcontainer {
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    /* background: rgb(240, 213, 123); */
    background-color:rgba(255, 255, 255, 0.1);
  }
  
  .datagrid-container {
    width: 100%;
    overflow-x: auto;
    background: 'rgba(255, 255, 255, 0.1)',
  }
  
  /* Custom styling for MUI components */
  .MuiButton-root {
    text-transform: none;
    font-weight: 500;
  }
  
  .MuiDataGrid-root{
    font-family: inherit;
  }


.MuiTablePagination-actions .MuiIconButton-root {
  color: white !important;
}


.MuiTablePagination-selectLabel,
.MuiTablePagination-select {
  color: white !important;
}

.MuiTablePagination-selectIcon {
  color: white !important;
}

.MuiTablePagination-displayedRows {
  color: white !important;
}

.MuiDataGrid-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.MuiDataGrid-scrollbar::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1); 
  border-radius: 1px;
}

.MuiDataGrid-scrollbar::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 4px; 
}

.MuiDataGrid-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: dimgray;
}
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .leaguemgmtbody {
      padding: 120px 0 60px 0;
    }
    
    .leaguemgmtcontainer {
      width: 95%;
      padding: 15px;
    }
  }