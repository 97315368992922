.waiverpage {
    margin-top: 0;
    padding: 10px 0 0px 0;
    background: linear-gradient(90.21deg, rgba(21, 18, 97, 0.5) -5.91%, rgba(0, 34, 81, 0.5) 111.58%);
    height: 100vh;
}
  
.waiverpage .waiversystem {
    margin-top: 160px;
    margin-left: 10px 15px;
    margin-bottom: -160px;
    background: #d5c4c4;
    width: 100%;
    height: 75vh;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    overflow-y: auto;
  }
  
  .waiverpage .waiversystem h1 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .waiverpage .waiversystem h2 {
    margin-bottom: 15px;
    font-size: 20px;
    color: black;
  }

  .waiverpage .waiversystem h3 {
    margin-bottom: 15px;
    font-size: 20px;
    color: black;
  }

  .waiverpage .waiversystem h4 {
    margin-bottom: 15px;
    font-size: 20px;
    color: black;
  }


  .waiverpage .waiversystem .preference-section,
  .waiverpage .waiversystem .drop-section {
    margin-bottom: 20px;
    
  }
  
  .preference-input,
  .drop-input {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .waiverpage .waiversystem .preference-section label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: black;
  }

  .waiverpage .waiversystem .drop-section label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: black;
  }

  .ant-select .ant-select-selection-placeholder {
    color: red; /* Change this to the desired color */
    font-weight: bold; /* Optional: Make the placeholder text bold */
  }

  
  .ant-select .ant-select-clear {
    color: red; 
    font-size: large
  }

  /*
  .waiverpage .waiversystem .preference-section Select {
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #333;
    color: white;
  }
  
  .waiverpage .waiversystem .drop-section Select {
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #333;
    color: white;
  }*/
  /*
  input[type="text"]::placeholder,
  select {
    color: #999;
  }*/
  
  .drop-input {
    flex: 1;
    margin-right: 10px;
  }
  
  .drop-input:last-child {
    margin-right: 0;
  }
  
 
.waiverpage .waiversystem button{
    border-radius: 5px;
    margin-bottom: 5px;
    margin: 5px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.waiverpage .waiversystem button:hover {
    background-color: #45a049;
}
  
.waiverpage .waiversystem .main-content {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.waiverpage .waiversystem .sidebar {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
}

.waiverpage .waiversystem .logbar {
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
}

.waiverpage .waiversystem .logbar .gif-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.waiverpage .waiversystem .logbar .gif-container .gif {
  width: 575px; /* Adjust the size as needed */
  height: auto;
}
