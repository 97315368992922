.modal-waiver-results-container {
    width: 100%;
    max-width: 1500px; /* Increased max-width */
    margin: 0 auto;
  }
  
  .modal-all-rounds-container {
    display: flex;
    flex-direction: column;
    background-color: rgba(38, 38, 38, 0.6) !important;
  }
  
  .modal-round-section {
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    margin-bottom: 0.75rem;
    padding: 0.75rem;
  }
  
  .modal-round-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 0.75rem;
    color: #2563eb;
    font-size: 1.1rem;
  }
  
  .modal-waiver-order {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f9fafb; */
    background-color: rgba(38, 38, 38, 0.6) !important;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
  
  .modal-waiver-order-item {
    font-size: 0.8rem;
    padding: 0.1rem 0.3rem;
    /* background-color: #e9ecef; */
    background-color: rgba(38, 38, 38, 0.6) !important;
    border-radius: 0.2rem;
    color: #e9ecef;
  }
  
  .modal-picks-grid-all-rounds {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 0.5rem;
    width: 100%;
  }
  
  .modal-pick-card-small {
    border-radius: 0.5rem;
    padding: 0.5rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    height: 180px; 
    overflow: hidden;
    text-align: center;
    justify-content: space-between;
  }
  
  .modal-pick-header-small {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.25rem;
  }
  
  .modal-team-identifier-small {
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
    color: #000300;
  }
  
  .modal-status-indicator-small {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
    text-align: center;
    margin-bottom: 0.25rem;
  }
  
  .modal-status-icon {
    margin-right: 0.25rem;
    width: 13px;
    height: 13px;
  }
  
  .pick-details-small {
    font-size: 0.7rem;
    line-height: 1.2;
    color: black;
  }
  
  .pick-details-small div {
    margin-bottom: 0.2rem;
  }

  .pick-message {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: rgba(255,255,255,0.7);
  }
  
  .success-bg { 
    background-color: #7eeca4; 
    border: 1px solid #6cd793;
  }
  .success-text { color: #166534; }
  .failure-bg { 
    background-color: #f4c4c4; 
    border: 1px solid #fca5a5;
  }
  .failure-text { color: #991b1b; }

  .default-bg { 
    background-color: #c4e7f4; 
    border: 1px solid #a5dcfc;
  }
  .default-text { color: #1b7999; }
  
  .modal-rounds-selector {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0.75rem;
    background-color:  rgba(38, 38, 38, 0.6) !important;
    gap: 0.5rem;
    border-radius: 8px;
  }
  
  @media (max-width: 1200px) {
    .modal-picks-grid-all-rounds {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .modal-picks-grid-all-rounds {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .modal-picks-grid-all-rounds {
      grid-template-columns: 1fr;
    }
  }

.MuiCard-root.modal-waiver-results-container-card{
  background-color: rgba(38, 38, 38, 0.6) !important;
  color: white !important;
}