.signin-page{
  margin-top: 0;
  padding: 140px 0 100px 0;
  background: linear-gradient(90.21deg, rgba(21, 18, 97, 0.5) -5.91%, rgba(0, 34, 81, 0.5) 111.58%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  }

.signin-page h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .signin-page {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .signin-page h2 {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .signin-page {
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .signin-page h2 {
      font-size: 1.2rem;
    }
  }