.line-graph-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  padding-top: 110px;
  background: linear-gradient(90.21deg, rgba(21, 18, 97, 0.5) -5.91%, rgba(0, 34, 81, 0.5) 111.58%);
  width: 100%;
  margin: 0 auto;
}

.line-graph-card {
  width: 95%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 0px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin: 10px;
  overflow: visible; /* Changed from hidden to allow hover tooltips to appear outside the container */
}

.ant-card .ant-card-body{
  padding: 0;
}

.ant-breadcrumb-link {
  /* margin-bottom: 1rem; */
  font-size: 14px;
  padding: 10px 0;
  color: rgba(255,255,255,0.7);
}

.standings-breadcrumb {
  /* margin-bottom: 1rem;
  font-size: 14px; */
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.chart-container {
  position: relative;
  padding: 1rem 0;
  overflow-x: auto; /* Added horizontal scrolling for many data points */
  overflow-y: hidden;
  height: auto; /* Changed from fixed height to auto */
  min-height: 500px; /* Minimum height to ensure visibility */
  width: 100%;
  border-radius: 8px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 100%;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .line-graph-card {
    width: 90%; /* Increased from 80% to maximize space */
    padding: 15px;
  }

  .chart-container {
    min-height: 450px;
  }
}

/* For mobile phones */
@media (max-width: 768px) {
  .line-graph-page {
    padding-top: 110px;
  }
  
  .line-graph-card {
    width: 95%;
    padding: 10px;
    margin: 5px;
  }
  
  .chart-container {
    min-height: 400px;
    padding: 0.5rem 0;
  }
}

/* For very small screens */
@media (max-width: 480px) {
  .line-graph-page {
    padding-top: 110px;
  }
  
  .chart-container {
    min-height: 350px;
  }
}

/* Dark mode compatibility - modified to match team page styling */
@media (prefers-color-scheme: dark) {
  .line-graph-card {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  .standings-breadcrumb {
    border-color: rgba(255, 255, 255, 0.1);
  }
}