nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  transition:  0.32s ease-in-out;
  height: 90px;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}

nav.navbar  span.navbar-brand a.navbar-brand img {
  width: 55%;
  height: auto;
  margin: 5px 0;
}

/*
nav.navbar.logged {
  padding: 0px 0;
  background-color: #121212;
}*/

@media screen and (max-width: 768px) {
  nav.navbar span.navbar-brand ,
  nav.navbar span.navbar-brand a.navbar-brand{
      width: 150px; 
  }
}

@media screen and (min-width: 768px) {
  nav.navbar span.navbar-brand ,
  nav.navbar span.navbar-brand a.navbar-brand{
      width: 18%;
  }
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 420;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 21px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
    border-bottom: 2px solid #fff;
}
span.navbar-text {
    display: flex;
    align-items: center;
}

/* Social icon styling */
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
    transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
    width: 40%;
    z-index: 1;
    transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
    filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

/* User avatar styling - explicit hover effect */
.nav-item.dropdown a#user-dropdown .user-avatar {
    /* width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
    transition: 0.3s ease-in-out; */
    display: inline-block;
    margin-left: 14px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative; /* Added for consistent hover effect */
    overflow: hidden;
    background: rgba(217, 217, 217, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    transition: background-color 0.3s ease;
    background-color: rgba(255, 255, 255, 0.9);;
}
.nav-item.dropdown a#user-dropdown .user-avatar::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
    transition: 0.3s ease-in-out;
}
.nav-item.dropdown a#user-dropdown:hover .user-avatar::before {
    transform: scale(1);
}

.nav-item.dropdown a#user-dropdown .user-avatar:hover {
  background-color: rgba(231, 228, 228, 0.1);
}

.nav-item.dropdown a#user-dropdown .user-avatar svg {
    width: 40%;
    z-index: 1;
    transition: 0.3s ease-in-out;
}
.nav-item.dropdown a#user-dropdown:hover .user-avatar svg {
    filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

/* Explicitly make dropdown arrow white */
.dropdown-toggle::after {
    color: white !important;
    border-top-color: white !important;
}

/* Force white dropdown arrow */
.nav-item.dropdown .dropdown-toggle:after {
    border-top: 0.3em solid white !important;
    border-right: 0.3em solid transparent !important;
    border-bottom: 0 !important;
    border-left: 0.3em solid transparent !important;
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
  border-radius: 50px;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
  border-radius: 50px;
}
.navbar-text button:hover {
  color: #121212;
  border-radius: 50px;
}
.navbar-text button:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50px;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
    border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
    width: 24px;
    position: absolute;
    height: 2px;
    background-color: #fff;
    top: 0;
    left: 0;
    content: '';
    z-index: 2;
    transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
    top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
    transform: rotate(45deg);
    background-color: #fff;
    height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
    transform: translateY(8px) rotate(-45deg);
    background-color: #fff;
    height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    border-color: transparent;
}

.offcanvas {
  background-color:rgb(197, 109, 2)  !important;
  width: 100%;
  max-width: 320px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  transform: translateX(0);
  transform: translateX(100%);
}

.offcanvas-body {
  padding: 0px;
  background-color:rgb(197, 109, 2)  !important; 
}

@media screen and (min-width: 768px) {
  .offcanvas {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .navbar-collapse {
    display: none;
  }
}

.offcanvas-body .nav-link {
  font-weight: 500;
  color: black !important;
  letter-spacing: 0.8px;
  padding: 10px 25px;
  font-size: 20px;
  opacity: 0.75;
}

.offcanvas-body .nav-link:hover,
.offcanvas-body .nav-link.active {
  opacity: 1;
}

.offcanvas-body .navbar-text {
  display: flex;
  align-items: center;
}

/* Offcanvas social icon styling */
.offcanvas-body .social-icon {
  display: inline-block;
  margin-left: 14px;
}
.offcanvas-body .social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.offcanvas-body .social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.offcanvas-body .social-icon a:hover::before {
  transform: scale(1);
}
.offcanvas-body .social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.offcanvas-body .social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

/* Offcanvas user avatar styling - with improved hover effect */
.offcanvas-body .nav-item.dropdown a#user-dropdown .user-avatar {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.offcanvas-body .nav-item.dropdown a#user-dropdown .user-avatar::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.offcanvas-body .nav-item.dropdown a#user-dropdown:hover .user-avatar::before {
  transform: scale(1);
}
.offcanvas-body .nav-item.dropdown a#user-dropdown .user-avatar svg {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.offcanvas-body .nav-item.dropdown a#user-dropdown:hover .user-avatar svg {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

/* Offcanvas dropdown arrow */
.offcanvas-body .dropdown-toggle::after {
  color: black !important;
  border-top-color: black !important;
}

.offcanvas-body button {
  font-weight: 700;
  color: black !important;
  border: 1px solid black !important;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
  border-radius: 50px;
}

.offcanvas-body button span {
  z-index: 1;
}

.offcanvas-body button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: black !important;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out !important;
  border-radius: 50px;
}

.offcanvas-body button:hover {
  color: black !important;
  border-radius: 50px;
}

.offcanvas-body button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50px;
}

.offcanvas-header .btn {
  color: black;
  background-color: transparent;
  max-width: 25%;
  border: none;
}