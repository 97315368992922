.custom-loading-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 20px;
  }
  
  .loading-text {
    margin-top: 10px;
    font-weight: 500;
  }