.videopage {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 2rem 0;
    /* margin: 0 auto; */
    padding: 60px 0 100px 0;
    background: linear-gradient(
        90.21deg,
        rgba(21, 18, 97, 0.5) -5.91%,
        rgba(0, 34, 81, 0.5) 111.58%
    );
  }
  
  .videopage h1 {
    text-align: center;
    /* margin-bottom: 2rem;
    font-size: 2.5rem; */
    padding: 30px;
  }
  
  .video-container {
    width: 60%;
    height: 95vh;
    position: relative;
  }
  
  .video-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 768px) {
    .video-container {
      width: 95%;
      height: 35vh;
    }
    
    h1 {
      font-size: 1.8rem;
      margin-bottom: 1.5rem;
    }
  }