.teampage {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  padding-top: 110px;
  background: linear-gradient(90.21deg, rgba(21, 18, 97, 0.5) -5.91%, rgba(0, 34, 81, 0.5) 111.58%);
}

.teampagecontainer {
  width: 60%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.teams-main-container {
  height: 500px;
  width: 100%;
  border-radius: 8px;
}

.custom-modal .ant-modal-content {
  background-color: #333;
  color: white;
}

.custom-modal .ant-modal-header {
  background-color: #333;
  border-bottom: 1px solid #555;
}

.custom-modal .ant-modal-title {
  color: white;
}

.custom-modal .anticon svg {
  color: white;
}

/* Add hover effect */
.custom-modal .anticon svg:hover {
  background-color: #c82333;
  border-color: #bd2130;
  color: white;
}

@media (max-width: 1024px) {
  .teampagecontainer {
    width: 80%; 
    padding: 15px;
  }

  .teams-main-container {
    height: 700px; 
  }
}

/* For mobile phones */
@media (max-width: 768px) {
  .teampagecontainer {
    width: 95%; 
    padding: 10px;
    margin: 5px;
  }

  .teams-main-container {
    height: 600px; 
  }

  .teampage {
    padding-top: 110px;
  }
}

/* For very small screens */
@media (max-width: 480px) {
  .teams-main-container {
    height: 500px;
  }
  .teampage {
    padding-top: 110px;
  }
}