.auction-page {
  min-height: 100vh;
  background: linear-gradient(90.21deg, rgba(21, 18, 97, 0.5) -5.91%, rgba(0, 34, 81, 0.5) 111.58%);
  padding: 20px 0;
  color: #fff;
  font-family: 'Inter', 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-container {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-top: 75px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 120px);
  display: grid;
  grid-template-columns: 75% 24%; /* 65% width for player details/team buttons, 34% for owner table/next player */
  grid-template-rows: 75% 24%; /* 65% height for player details/owner table, 34% for team buttons/next player */
  column-gap: 1%; /* 1% gap between columns */
  row-gap: 1%; /* 1% gap between rows */
}

/* Player Display Section (Top Left) - player details, player card and bid */
.player-display {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.player-card-wrapper {
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: 50%;
}

.sold-tag, .unsold-tag {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-weight: 800;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
  letter-spacing: 3px;
  padding: 10px 20px;
  border-radius: 8px;
  z-index: 10;
}

.sold-tag {
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
}

.unsold-tag {
  color: #fff;
  background-color: rgba(108, 117, 125, 0.8);
}

/* Bidding Area */
.bidding-area {
  display: flex;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 14px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: 45%;
}

.bid-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 60%;
}

.bid-detail {
  display: flex;
  align-items: center;
  gap: 9px;
}

.label {
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  width: 120px;
}

.value {
  font-size: 15px;
  font-weight: 600;
  color: white;
  background-color: rgba(255, 255, 255, 0.15);
  padding: 3px 8px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 100px;
  text-align: center;
}

.bid-input {
  width: 100px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  padding: 3px 8px;
  border-radius: 6px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.timer-and-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 35%;
}

.timer {
  font-size: 45px;
  font-weight: 700;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
  color: white;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.timer-warning {
  background-color: rgba(220, 53, 69, 0.6);
  animation: pulse 1s infinite;
  box-shadow: 0 0 20px rgba(220, 53, 69, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.action-buttons {
  display: flex;
  gap: 10px;
  width: 100%;
}

.action-buttons .btn {
  flex: 1;
  padding: 10px;
  font-weight: 600;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.owner-stats-container {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.owner-stats-container .MuiTableContainer-root {
  overflow-x: auto; 
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.owner-stats-container .MuiTable-root {
  width: 100%;
  border-collapse: collapse;
  background-color: lightgray;
}

.owner-stats-container .MuiTableHead-root {
  background-color: gray;
}

.owner-stats-container .MuiTableCell-root {
  padding: 8px 10px; /* Reduced padding */
  text-align: center; /* Center align all cells */
  font-size: 15px; /* Reduced font size */
  border-bottom: 3px solid rgba(255, 255, 255, 0.1); /* Add subtle bottom border */
}

.owner-stats-container .MuiTableCell-head {
  font-weight: 800;
  background-color: rgba(255, 255, 255, 0.1); 
}

.owner-stats-container .MuiTableCell-body {
  font-weight: 600; 
}

.owner-stats-container .MuiTableRow-root {
  height: 40px; 
}

.owner-stats-container .MuiTableRow-root:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.05);
}

.owner-stats-container .MuiTableCell-root img,
.owner-stats-container .MuiTableCell-root svg {
  vertical-align: middle;
}
/* Team Buttons (Bottom Left) */
.team-buttons {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: auto;
  /* border: 1px solid rgba(255, 255, 255, 0.5); */
}

.team-buttons-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.team-btn-container {
  position: relative;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.team-btn-container button {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
  /* background: linear-gradient(135deg, #c28bf5 0%, #6d387e 100%); */
  background: linear-gradient(135deg, #5d79fb 0%, #2d46b5 100%);
  color: white;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 8px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.team-btn-container button:hover:not([disabled]) {
  background: linear-gradient(135deg, #f37c6fd7 0%, #d83d1eda 100%);
  transform: translateY(-2px);
}

.team-btn-container.selected button {
  background: linear-gradient(135deg, #6bf06bbe 0%, #09c722cf 100%);
  box-shadow: 0 0 10px rgba(239, 155, 9, 0.5);
}

.team-btn-container.disabled button {
  background: linear-gradient(135deg, #9da5b4 0%, #6c757d 100%);
  cursor: not-allowed;
  opacity: 0.6;
}

.bid-paddle {
  position: absolute;
  top: -20px;
  width: 60px;
  height: 60px;
  z-index: 5;
  transform-origin: bottom center;
  animation: wiggle 2s infinite;
}

@keyframes wiggle {
  0%, 100% { transform: rotate(-5deg); }
  50% { transform: rotate(5deg); }
}

/* Next Player Controls (Bottom Right) */
.next-player-controls {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-player {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.player-search-input {
  width: 100%;
  padding: 8px 10px;
  border-radius: 6px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  font-size: 14px;
}

.player-search-input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.player-search-input:focus {
  outline: none;
  border-color: #3e63dd;
  box-shadow: 0 0 8px rgba(62, 99, 221, 0.5);
}

.search-player .btn {
  width: 100%;
  padding: 8px;
  font-weight: 600;
  font-weight: 14px;
}

/* Button styles */
.btn-primary {
  background-color: #10b981;
  border-color: #10b981;
}

.btn-primary:hover:not([disabled]) {
  background-color: #2e53cd;
  border-color: #2e53cd;
  transform: translateY(-2px);
}

.btn-success {
  background-color: #3e63dd;
  border-color: #3e63dd;
}

.btn-secondary {
  background-color: #710808;
  border-color: #710808;
}

.btn-outline-secondary {
  color: white;
  border-color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:hover:not([disabled]) {
  background-color: #6c757d;
  color: white;
}

.btn:disabled {
  background-color: rgba(108, 117, 125, 0.5);
  border-color: rgba(108, 117, 125, 0.3);
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .main-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    gap: 15px;
  }
  
  .player-display {
    grid-column: 1;
    grid-row: 1;
  }
  
  .owner-stats-container {
    grid-column: 1;
    grid-row: 2;
  }
  
  .team-buttons {
    grid-column: 1;
    grid-row: 3;
  }
  
  .next-player-controls {
    grid-column: 1;
    grid-row: 4;
  }
  
  .bidding-area {
    flex-direction: column;
    gap: 20px;
  }
  
  .bid-info, .timer-and-actions {
    width: 100%;
  }
  
  .timer-and-actions {
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .main-container {
    margin-top: 60px;
    padding: 15px;
  }
  
  .team-buttons-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
  
  .timer {
    font-size: 36px;
    width: 80px;
    height: 80px;
  }
  
  .bid-paddle {
    width: 60px;
    height: 60px;
    top: -35px;
  }
}

@media (max-width: 576px) {
  .team-buttons-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}