.rulespage {
  text-align: center;
  margin: 0 auto;
  padding: 60px 0 100px 0;
  background: linear-gradient(
    90.21deg,
    rgba(21, 18, 97, 0.5) -5.91%,
    rgba(0, 34, 81, 0.5) 111.58%
  );
}

.rulespage h1 {
  text-align: center;
  padding: 30px;
  color: white;
}

.rulespage .accordion-item {
  border: none;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px 50px;
}

.rulespage .accordion-header {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  font-weight: bold;
  padding: 15px;
  border-radius: 10px;
}

.rulespage .accordion-body {
  padding: 20px;
  color: white;
  text-align: left;
}

/* New styles for formatted rule content */
.rule-content {
  padding: 10px 15px;
}

.rule-content h4 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #fff;
  text-decoration: underline;
}

.rule-content p {
  margin-bottom: 10px;
  line-height: 1.5;
}

.rule-content ul {
  margin-left: 20px;
  margin-bottom: 20px;
  list-style-type: disc;
}

.rule-content li {
  margin-bottom: 8px;
  line-height: 1.4;
}

.rule-content strong {
  font-weight: bold;
}

/* Rest of your existing styles */
.rulespage .accordion-button {
  background-color: transparent;
  color: white;
  font-weight: bold;
  border: none;
  padding: 15px;
  position: relative;
  padding-right: 30px;
}

.rulespage .accordion-button:focus {
  box-shadow: none;
  outline: none;
}

/* Remove the default arrow */
.rulespage .accordion-button::after {
  display: none !important;
}

/* Add custom + icon when collapsed */
.rulespage .accordion-button.collapsed::before {
  content: '+';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: white;
  font-size: 1.5rem;
  display: block;
}

/* Add custom - icon when expanded */
.rulespage .accordion-button:not(.collapsed)::before {
  content: '-';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: white;
  font-size: 1.5rem;
  display: block;
}

/* Prevent bootstrap from adding blue background when expanded */
.rulespage .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}

/* Fix for button padding and appearance */
.rulespage .accordion-header button {
  background-color: transparent;
  color: white;
  font-weight: bold;
  border: none;
  position: relative;
  width: 100%;
  text-align: left;
  box-shadow: none;
  outline: none;
}

.rulespage .accordion-header button:focus {
  outline: none;
  box-shadow: none;
}

/* Make sure the custom indicators appear in all states */
.rulespage .accordion-button:hover::before,
.rulespage .accordion-button:focus::before,
.rulespage .accordion-button:active::before {
  display: block;
}

/* Responsive styles - Large devices (desktops) */
@media (max-width: 1200px) {
  .rulespage .accordion-item {
    margin: 20px 40px;
  }
}

/* Medium devices (tablets) */
@media (max-width: 992px) {
  .rulespage {
    padding: 40px 0 80px 0;
  }
  
  .rulespage .accordion-item {
    margin: 15px 30px;
  }
  
  .rulespage h1 {
    padding: 20px;
    font-size: 1.8rem;
  }
}

/* Small devices (landscape phones) */
@media (max-width: 768px) {
  .rulespage {
    padding: 30px 0 60px 0;
  }
  
  .rulespage .accordion-item {
    margin: 15px 20px;
  }
  
  .rulespage h1 {
    padding: 15px;
    font-size: 1.6rem;
  }
  
  .rulespage .accordion-body {
    padding: 15px;
  }
  
  .rule-content {
    padding: 5px 10px;
  }
}

/* Extra small devices (phones) */
@media (max-width: 576px) {
  .rulespage {
    padding: 20px 0 40px 0;
  }
  
  .rulespage .accordion-item {
    margin: 10px 15px;
  }
  
  .rulespage h1 {
    padding: 10px;
    font-size: 1.4rem;
  }
  
  .rulespage .accordion-header {
    padding: 10px;
  }
  
  .rulespage .accordion-button {
    padding: 10px;
    padding-right: 25px;
    font-size: 0.9rem;
  }
  
  .rulespage .accordion-button.collapsed::before,
  .rulespage .accordion-button:not(.collapsed)::before {
    font-size: 1.2rem;
    right: 10px;
  }
  
  .rulespage .accordion-body {
    padding: 10px;
  }
  
  .rule-content h4 {
    font-size: 1.1rem;
  }
  
  .rule-content p,
  .rule-content li {
    font-size: 0.9rem;
  }
}